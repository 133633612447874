require('../css/app.scss');
const $ = require('jquery');
const basePath = 'https://gestionageval.euro-info.fr/';
require('bootstrap');
const Swal = require('sweetalert2');

var message = '';
var mes = 0;
var page = '';

$(document).ready(function(){
    $('[data-toggle="popover"]').popover();
    $('select[name=cbx_liste]').trigger('change');

    $('#BoutonDocuments').click(function(){
        window.location.href = basePath + 'redirect/documents';
    });

    $('#BoutonPrevisionnel').click(function(){
        window.location.href = basePath + 'redirect/previsionnel';
    });

    $('#salarie_rad_ajout_1').change(function(){
        if($(this).is(':checked')){
            $('input[name=btnNouvelleMesure]').prop('disabled',true);
            $('select[name=salarie_cbx_titre]').val(-1);
            $('input[name=salarie_txt_nom]').val('');
            $('input[name=salarie_txt_prenom]').val('');
            $('input[name=btnEnregistrerSalarie]').val('Enregistrer');
        }
    });
    $('#salarie_rad_ajout_2').change(function(){
        if($(this).is(':checked')){
            $('input[name=btnNouvelleMesure]').prop('disabled',false);
            $('select[name=salarie_cbx_titre] option:contains(' + $('input[name=txt_titre]').val() + ')').prop('selected',true);
            $('input[name=salarie_txt_nom]').val($('input[name=txt_nom]').val());
            $('input[name=salarie_txt_prenom]').val($('input[name=txt_prenom]').val());
            $('input[name=btnEnregistrerSalarie]').val('Enregistrer');
        }
    });
    $('#salarie_rad_ajout_3').change(function(){
        if($(this).is(':checked')){
            $('input[name=btnNouvelleMesure]').prop('disabled',true);
            $('select[name=salarie_cbx_titre] option:contains(' + $('input[name=txt_titre]').val() + ')').prop('selected',true);
            $('select[name=salarie_cbx_titre]').prop('disabled',true);
            $('input[name=salarie_txt_nom]').val($('input[name=txt_nom]').val());
            $('input[name=salarie_txt_nom]').prop('disabled', true);
            $('input[name=salarie_txt_prenom]').val($('input[name=txt_prenom]').val());
            $('input[name=salarie_txt_prenom]').prop('disabled', true);
            $('input[name=btnEnregistrerSalarie]').val('Supprimer');
        }
    });
    $('#salarie_rad_ajout_1').prop('checked',true);
    $('#salarie_rad_ajout_1').trigger('change');

    $('input[name=btnNouvelleMesure]').click(function(){
        message = 'Une nouvelle mesure a été créée pour ce salarié.';
        $.ajax({
            url: basePath + 'salarie/mesure',
            dataType: 'json',
            type:'POST',
            success: ModificationReussie
        });
    });

    $('input[name=btnEnregistrerSalarie]').click(function(){
        if($('#salarie_rad_ajout_1').is(':checked') || $('#salarie_rad_ajout_2').is(':checked')){
            if($('#salarie_rad_ajout_2').is(':checked')) message = 'Modification des informations du salarié réussie.';
            else message = 'Ajout du nouveau salarié réussi.';
            var data = "titre=" + $('select[name=salarie_cbx_titre]').val() + "&nom=" + $('input[name=salarie_txt_nom]').val();
            data += "&prenom=" + $('input[name=salarie_txt_prenom]').val() + "&update=" + ($('#salarie_rad_ajout_2').is(':checked') ? 1 : 0);
            $.ajax({
                url: basePath + 'salarie/post',
                data: data,
                dataType: 'json',
                type:'POST',
                success: ModificationReussie
            });
        }
        else if($('#salarie_rad_ajout_3').is(':checked')){
            message = 'Suppression des informations du salarié réussie.';
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir supprimer ce salarié ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Supprimer',
                cancelButtonText: 'Annuler',
                focusCancel: true
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Cette action entraînera la suppression de toutes les informations du salarié.\nConfirmer ?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Supprimer',
                        cancelButtonText: 'Annuler',
                        focusCancel: true
                    }).then((result) => {
                        if (result.value) {
                            $.ajax({
                                url: basePath + 'salarie/delete',
                                dataType: 'json',
                                type:'POST',
                                success: ModificationReussie
                            });
                        }
                    })
                }
            });
        }
    });
});

$('select[name=cbx_liste]').change(function(){
    $.ajax({
        url: basePath + 'informations/' + $('select[name=cbx_liste] option:selected').val() + '/' + mes,
        dataType: 'json',
        type:'GET',
        success: AfficherInformations
    });
});
function AfficherInformations(data){
    $('#ApresMesure').show();
    $("#AvantMesure").show();
    if(data.salarie.numero == data.salarie.numero_max)
        $('#ApresMesure').hide();
    else if(data.salarie.numero == 1)
        $("#AvantMesure").hide();
    $('input[name=txt_titre]').val(data.salarie.titre);
    $('input[name=txt_nom]').val(data.salarie.nom);
    $('input[name=txt_prenom]').val(data.salarie.prenom);
    $('input[name=txt_mesure_1]').val(data.salarie.numero);
    $('input[name=txt_mesure_2]').val(data.salarie.numero_max);
    $('input[name=chk_disponible]').prop('checked',data.salarie.disponible > 1 ? false : true);
    if(data.salarie.etat > 0){
        $('input[name=txt_etat]').val('Présent');
        $('input[name=txt_etat]').css('background-color','greenyellow');
    }
    else{
        $('input[name=txt_etat]').val('Sorti');
        $('input[name=txt_etat]').css('background-color','sandybrown');
    }
}
function ModificationReussie(data){
    if(!data.modificationReussie) FireError('Une erreur est survenue lors de la mise à jour !');
    else FireSuccess(message);
}

function FireError(msg = ''){
    if(msg == '') msg = 'Une erreur est survenue lors de l\'enregistrement.\nVeuillez réessayer ultérieurement.'
    Swal.fire({
        icon: 'error',
        title: msg
    });
}

function FireSuccess(msg = ''){
    if(msg == '') msg = 'Les données ont été enregistrées avec succès.'
    Swal.fire({
        icon: 'success',
        title: msg
    }).then((result) => {location.reload();});
}

$('#AvantMesure').click(function(){
    mes = parseInt($('input[name=txt_mesure_1]').val()) - 1;
    $('select[name=cbx_liste]').trigger('change');
});

$('#ApresMesure').click(function(){
    mes = parseInt($('input[name=txt_mesure_1]').val()) + 1;
    $('select[name=cbx_liste]').trigger('change');
});

function ExportCV(data){
    if(data.fichier != ""){
        Swal.fire({
            icon: 'success',
            title: 'Le CV a été exporté avec succès.'
        });
        window.open(basePath + 'rh/cv/exporter/' + data.fichier, '_blank');
            var monFichier = "monFichier=" + data.fichier;
            $.ajax({
                url: basePath + 'rh/cv/unlink',
                data: monFichier,
                dataType: 'json',
                type:'POST'
            });
    }
    else FireError("Une erreur est survenue lors de l'export du CV.\nVeuillez réessayer ultérieurement.");
}

$("#ExportCV").click(function(){
    $.ajax({
        url: basePath + 'rh/cv/export/metiers',
        dataType: 'json',
        type:'POST',
        success: function(d) {
            var htmlMetiers = '';
            for(var y = 0; y < d.listMetiers.length; y++)
                htmlMetiers += '<option value="' + d.listMetiers[y].id + '">' + d.listMetiers[y].nom + '</option>';
            Swal.fire({
                title: 'Métier',
                html:
                    '<select id="swall-select-metier">' + htmlMetiers + '</select><br>',
                showCancelButton: true,
                confirmButtonText: 'Exporter',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
                preConfirm: () => { return [ document.getElementById('swall-select-metier').value ] }
            }).then((result) => {
                if(result){
                    if(result.value){
                        var data = "metier=" + result.value[0];
                        $.ajax({
                            url: basePath + 'rh/cv/export',
                            data: data,
                            dataType: 'json',
                            type:'POST',
                            success: ExportCV
                        });
                    }
                }
            });
        }
    })
});